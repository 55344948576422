import { json, LoaderFunction, MetaFunction } from "@remix-run/node";
import {
    Links,
    LiveReload,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useLocation,
} from "@remix-run/react";
import { useEffect } from "react";
import { getEnv } from "./env.server";

import remixImageStyles from "remix-image/remix-image.css";
import styles from "./styles/app.css";
import { checkEnvVars } from "~/utils/errorHandling";
import * as gtag from "~/utils/gtags.client";

export function links() {
    return [
        {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png",
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: ENV.WEB_URL + "/favicon-32x32.png",
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: ENV.WEB_URL + "/favicon-16x16.png",
        },
        { rel: "manifest", href: "/site.webmanifest" },
        { rel: "preconnect", href: "https://fonts.googleapis.com" },
        {
            rel: "preconnect",
            href: "https://fonts.gstatic.com",
            crossOrigin: "",
        },
        { rel: "stylesheet", href: remixImageStyles },
        { rel: "stylesheet", href: styles },
        {
            rel: "stylesheet",
            href: "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Encode+Sans:wght@300;400;700&display=swap",
        },
        {
            rel: "stylesheet",
            href: "https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css",
        },
    ];
}

export const loader: LoaderFunction = async () => {
    checkEnvVars();

    const resMetaData = await fetch(
        process.env.STRAPI_URL_BASE + "/api/meta-info?populate=*",
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${process.env.STRAPI_API_TOKEN}`,
                "Content-Type": "application/json",
            },
        },
    )
        .then((res) => res.json())
        .catch((error) => {
            console.log(error.message);
            throw new Response("Error getting data from Backend", {
                status: 500,
            });
        });

    // console.log(resMetaData.data.attributes)
    const metaInfo = resMetaData.data.attributes;

    return json({
        metaInfo,
        ENV: getEnv(),
        gaTrackingId: process.env.GA_TRACKING_ID,
    });
};

export const meta: MetaFunction = ({ data }) => {
    const { metaInfo } = data;

    return {
        charset: "utf-8",
        title: `${metaInfo.name} - ${metaInfo.tagline}`,
        description: metaInfo.description,
        viewport: "width=device-width,initial-scale=1",
        "og:type": "website",
        "og:url": ENV.WEB_URL,
        "og:title": `${metaInfo.name} - ${metaInfo.tagline}`,
        "og:description": metaInfo.description,
        "og:image": ENV.WEB_URL + "/meta-cover.png",
        "twitter:image": ENV.WEB_URL + "/meta-cover.png",
        "twitter:card": "summary_large_image",
        "twitter:creator": metaInfo.name,
        "twitter:site": metaInfo.name,
        "twitter:title": `${metaInfo.name} - ${metaInfo.tagline}`,
        "twitter:description": metaInfo.description,
    };
};

export default function App() {
    const location = useLocation();
    const { metaInfo, ENV, gaTrackingId } = useLoaderData();
    // console.log(metaInfo, ENV, gaTrackingId)
    const backgroundWeb = metaInfo.background.data?.attributes.url;

    useEffect(() => {
        if (gaTrackingId?.length) {
            gtag.pageview(location.pathname, gaTrackingId);
        }
    }, [location, gaTrackingId]);

    return (
        <html lang="en">
            <head>
                <Meta />
                <Links />
            </head>
            <body
                className={`${backgroundWeb ? "" : "polkaPattern"} bg-cover selection:bg-sky-300 selection:text-red-500`}
                style={
                    backgroundWeb && {
                        backgroundImage: `url(${ENV.BACKEND_URL + backgroundWeb})`,
                    }
                }
            >
                {/* Add Google Analytics */}
                {process.env.NODE_ENV === "development" ||
                !gaTrackingId ? null : (
                    <>
                        <script
                            async
                            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
                        />
                        <script
                            async
                            id="gtag-init"
                            dangerouslySetInnerHTML={{
                                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
                            }}
                        />
                    </>
                )}

                <Outlet />
                <ScrollRestoration />
                <Scripts />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.ENV = ${JSON.stringify(ENV)}`,
                    }}
                />
                <LiveReload />
            </body>
        </html>
    );
}
